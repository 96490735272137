@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
  }

  
  .app {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      overflow: hidden;
      padding: 2rem;
      --tw-text-opacity: 1;
    }

button {
    cursor: pointer;
}
    
html {
    scroll-behavior: smooth;
}

.logo {
    width: 160px;
    height: 100px;
}

ul {
    display: flex;
}

li {
    list-style-type: none;
}

.color__box {
    display: flex;
    display: inline-block;
    --tw-skew-x: -12deg;
    transform: translate(0, 0) rotate(0) skewX(var(--tw-skew-x)) skewY(0) scaleX(1) scaleY(1);
    --tw-bg-opacity: 1;
    background-color: rgb(88 219 163 / var(--tw-bg-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgb(247 250 252 / var(--tw-text-opacity));
}

/* 

NAV

*/

.nav__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
}

nav ul{
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu--open {
    display: flex;
}

.sidebar {
    position: fixed;
    top: 0; 
    right: 0;
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(12px);
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    list-style: none;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sidebar li {
    width: 100%;
    padding: 8px 8px;
}

.sidebar a{
    width: 100%;
}

.menu-button{
    display: none;
}

.nav__button {
    padding: 0;
    border: none;
    background: none;
}

.nav__link {
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    padding: .5rem 1rem;
    color: #243E63
}

/* 

LANDING

*/

#landing {
    display: flex;
}

header {
    margin-left: auto;
    margin-right: auto;
}

.header__container {
    display: flex;
    max-width: 1280px;
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.header__description--wrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 32rem;
    text-align: center;
}

.header__title {
    margin: 0px;
    text-align: center;
    line-height: 1.375;
    --tw-text-opacity: 1;
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
}

.header__para {
    font-size: 1rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    --tw-text-opacity: 1;
    font-weight: 500;
    line-height: 1.625;
    color: rgb(74 85 104 / var(--tw-text-opacity));
}

.landing__btn {
    border-radius: 9999px;
    margin-top: 2rem;
    display: inline-block;
    width: 14rem;
    text-align: center;
    letter-spacing: 0.025em;
    --tw-bg-opacity: 1;
    border-color: rgb(88 219 163 / var(--tw-bg-opacity));
    background-color: rgb(88 219 163 / var(--tw-bg-opacity));
    padding: 0.75rem 2rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(247 250 252 / var(--tw-text-opacity));
}

.header__img--wrapper {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
}

.header__img {
    width: 100%;
    min-width: 0px;
    max-width: 32rem;
    height: auto;
}

/* 

STATEMENT

*/

.statement__container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin: 2rem -2rem;
    --tw-bg-opacity: 1;
    background-color: rgb(88 219 163 / var(--tw-bg-opacity));
    padding-left: 2rem;
    padding-right: 2rem;
    --tw-text-opacity: 1;
    color: rgb(247 250 252)
}

.statement__wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.statement__title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
}

.statement__title--description {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    text-align: center;
    color: #e3e3e6;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
}

.statement__numbers--wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    display: flex;
    max-width: 768px;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
}

.number__wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    letter-spacing: 0.025em;
}

.number {
    font-size: 2.25rem;
    font-weight: 900;
}

.number__description {
    font-size: 1.25rem;
    font-weight: 500;
}

/* 

FEATURES

*/

.features__wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.features__title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
}

.services__wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    -webkit-box-align: center;
    align-items: center;
}

.service {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    height: 100%;
    max-width: 20rem;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0.25rem;
    padding: 2rem 1rem;
    text-align: center;
}

.service__icon--wrapper {
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(247 250 252 / var(--tw-bg-opacity));
    padding: 1rem;
    text-align: center;
}

.service__icon {
    height: 5rem;
    width: 5rem;
}

.service__title {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
}

.service__description {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(80 101 130 / var(--tw-text-opacity));
}

/* 

TESTIMONIALS

*/

.testimonials__container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.testimonials__wrapper {
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 28rem;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.testimonials__small--header {
    text-align: center;
}

.testimonials__header {
    display: none;
    width: 35%;
    margin-left: 3rem;
}

.testimonials__title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
    line-height: 1.25;
}

.testimonials__description {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    max-width: 28rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: rgb(124 139 161 / var(--tw-text-opacity));
}

.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    width: 20rem;
}

.slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    height: 100%;
}

.slide-hidden {
    display: none;
}

.arrow {
    position: absolute;
    height: 2rem;
    width: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555);
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}


.indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: grey;
}

/*

FOOTER

*/


footer {
    margin-left: -2rem;
    margin-right: -2rem;
    margin-bottom: -2rem;
    --tw-bg-opacity: 1;
    background-color: rgb(26 32 44 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(247 250 252 / var(--tw-text-opacity));
}

.footer__container {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer__logo {
    display: flex;
}
  
.footer__logo--img {
    width: 160px;
    filter: grayscale(1) invert(1);
}

.footer__info {
    text-align: center;
}

.link__border {
    border-style: solid;
    border-width: 0px;
}

.footer__link {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    border-bottom-width: 2px;
    border-color: transparent;
    padding-bottom: 0.25rem;
    text-decoration: inherit;
    transition-duration: 300ms;
    color: inherit;
}

.footer__link:hover {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

.footer__list {
    margin-top: 2rem;
    display: flex;
    flex-flow: column wrap;
    font-weight: 500;
    text-align: center;
}

.footer__copyright {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    --tw-text-opacity: 1;
    color: rgb(113 128 150 / var(--tw-text-opacity));
}

/*

ABOUT

*/

.about__wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    align-items: center;
    justify-content: space-between;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.about__description {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    width: 100%;
    max-width: 28rem;
    text-align: center;
}

.about__description-right {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    width: 100%;
    max-width: 28rem;
    text-align: center;
}

.about__header {
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(88 219 163);
    font-size: 0.875rem;
    letter-spacing: 0.05em;
}

.about__title {
    margin-top: 1rem;
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1.25;
    letter-spacing: 0.025em;
}

.about__para {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: rgb(124 139 161 / var(--tw-text-opacity));
}

.about__para--address {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: #243E63
}

.about__img--wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 28rem;
    flex-shrink: 0;
    position: relative;
    height: auto;
}

.about__img {
    border-radius: 0.25rem;
    max-width: 100%;
}

/*

CONTACT

*/

#contact {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.contact__container {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
}

.contact__img {
    width: 41.6667%;
    max-width: none;
}

.contact__title {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1.25;
    letter-spacing: 0.025em;
}

.contact__description {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: rgb(124 139 161 / var(--tw-text-opacity));
}

.formcarry-container {
    box-sizing: border-box;
    margin: 40px auto 40px auto;
    padding: 0;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;

    width: 300px;

    /* NORMAL */
    --fc-border-color: #ECEFF9;
    --fc-normal-text-color: #0E0B3D;
    --fc-normal-placeholder-color: #B3B8D0;

    /* PRIMARY COLOR | HSL FORMAT*/
    --fc-primary-color-hue: 154.4;
    --fc-error-color-hue: 356;
    --fc-primary-hsl: var(--fc-primary-color-hue), 64.5%, 60.2%;
    --fc-error-hsl: var(--fc-error-color-hue), 100%, 54%;

    /* HOVER */
    --fc-field-hover-bg-color: #F7F9FC;
    --fc-border-hover-color: #DDE0EE;
    --fc-field-hover-text-color: #B3B8D0;

    --fc-border-active-color: #1463FF;
  }

  .formcarry-container * {
    box-sizing: border-box;
  }

  .formcarry-container label {
    display: block;
    cursor: pointer;
  }

  .formcarry-container .formcarry-block:not(:first-child) {
    margin-top: 16px;
  }

  /*=============================================
  =            Fields           =
  =============================================*/
  
  .formcarry-container input,
  .formcarry-container textarea,
  .formcarry-container select {
    margin-top: 4px;
    width: 100%;
    height: 42px;
    border: 1px solid var(--fc-border-color);
    color: var(--fc-normal-text-color);
    border-radius: 6px;
    padding: 8px 12px;
    
    font-family: "Inter", sans-serif;
    font-size:14px;
    transition: 125ms background, 125ms color, 125ms box-shadow;
  }

  .formcarry-container textarea{
    min-height: 188px;
    max-width: 100%;
    padding-top: 12px;
  }

  .formcarry-container input::placeholder,
  .formcarry-container textarea::placeholder,
  .formcarry-container select::placeholder {
    color: var(--fc-normal-placeholder-color);
  }

  .formcarry-container input:hover,
  .formcarry-container textarea:hover,
  .formcarry-container select:hover {
    border-color: var(--fc-border-hover-color);
    background-color: var(--fc-field-hover-bg-color);
  }

  .formcarry-container input:hover::placeholder,
  .formcarry-container textarea:hover::placeholder,
  .formcarry-container select:hover::placeholder {
    color: var(--fc-field-hover-text-color);
  }

  .formcarry-container input:focus,
  .formcarry-container textarea:focus,
  .formcarry-container select:focus {
    background-color: #fff;
    border-color: hsl(var(--fc-primary-hsl));
    box-shadow: hsla(var(--fc-primary-hsl), 8%) 0px 0px 0px 3px;
    outline: none;
  }

  .formcarry-container select {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9997 14.5001L8.46387 10.9642L9.64303 9.78589L11.9997 12.1434L14.3564 9.78589L15.5355 10.9642L11.9997 14.5001Z' fill='%236C6F93'/%3E%3C/svg%3E%0A");
    /* background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px); */
    background-size: 24px 24px;
    background-position: 98%;
    background-repeat: no-repeat;
    appearance: none;
    -webkit-appearance: none;
  }

  .formcarry-container button {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.02em;
    height: 42px;
    line-height: 40px;
    width: 100%;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid hsla(var(--fc-primary-hsl));
    background-color: hsla(var(--fc-primary-hsl));
    color: #fff;
    cursor: pointer;
  }

  .formcarry-container button {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.02em;
    height: 40px;
    line-height: 24px;
    width: 100%;
    border: 0;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: hsla(var(--fc-primary-hsl));
    color: #fff;
    cursor: pointer;
    box-shadow: 0 0 0 0 transparent;
    
    transition: 125ms all;
  }
  
  .formcarry-container button:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), hsla(var(--fc-primary-hsl));
  }
  
  .formcarry-container button:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), hsla(var(--fc-primary-hsl));
    border-inline: 1px solid inline rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 0px 3px rgba(var(--fc-primary-hsl), 12%);
  }

  .formcarry-container button:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), hsla(var(--fc-primary-hsl));
  }

  .formcarry-container button:disabled {
    background-color: hsla(var(--fc-primary-hsl), 40%);
    cursor: not-allowed;
  }

  .formcarry-container input:focus:required:invalid,
  .formcarry-container input:focus:invalid, 
  .formcarry-container select:focus:required:invalid,
  .formcarry-container select:focus:invalid
  {
    color: hsl(var(--fc-error-hsl)); 
    border-color: hsl(var(--fc-error-hsl));
    box-shadow: 0px 0px 0px 3px hsla(var(--fc-error-hsl), 12%);
  }

/* 

RESPONSIVE

*/

@media(max-width: 800px){
    .hideOnMobile{
      display: none;
    }
    .menu-button{
      display: block;
    }
}
  
@media(max-width: 400px){
    .sidebar{
      width: 100%;
    }
}

@media (min-width: 640px) {
    .header__title {
        font-size: 3rem;
    }

    .statement__numbers--wrapper {
        flex-direction: row;
    }
    
    .number {
        font-size: 1.875rem;
    }

    .features__title {
        font-size: 3rem;
    }

    .testimonials__title {
        font-size: 3rem;
    }

    .testimonials__description {
        font-size: 1rem;
    }

    .carousel {
        height: 25rem;
        width: 35rem;
    }

    .footer__list {
        flex-direction: row;
    }

    .contact__title {
        font-size: 2.25rem;
    }

    .about__title {
        font-size: 2.25rem;
    }

}

@media (min-width: 768px) {
    .header__container {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .statement__title--description {
        font-size: 1rem;
    }
    
    .number {
        font-size: 2.25rem;
    }

    .contact__description {
        font-size: 1rem;
    }

    .contact__container {
        flex-direction: row;
    }

    .formcarry-container {
        width: 450px;
    }

    .about__wrapper {
        flex-direction: row;
    }

    .about__img--wrapper {
        margin-left: 0px;
        margin-right: 0px;
        max-width: none;
        width: 50%;
    }

    .about__description {
        margin-left: 0px;
        margin-right: 3rem;
        margin-top: 0px;
        max-width: none;
        width: 50%;
        order: -9999;
        text-align: left;
    }

    .about__description-right {
        margin-left: 3rem;
        margin-right: 0px;
        margin-top: 0px;
        max-width: none;
        width: 50%;
        order: 9999;
        text-align: left;
    }

    .about__para {
        font-size: 1rem;
    }

    .about__para--address {
        font-size: 1rem;
    }

}

@media (min-width: 1024px) {
    .header__container {
        flex-direction: row;
        align-items: center;
    }

    .header__description--wrapper {
        width: 41.6667%;
        max-width: none;
        text-align: left;
    }
    
    .header__title {
        text-align: left;
    }
    
    .header__para {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    
    .header__img--wrapper {
        margin-top: 0px;
        justify-content: flex-end;
    }

    .header__img {
        max-width: 48rem;
    }

    .statement__title {
        font-size: 3rem;
    }

    .statement__wrapper {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    
    .statement__title--description {
        font-size: 1.125rem;
    }

    .number {
        font-size: 3rem;
    }

    .features__wrapper {
    padding-top: 6rem;
    padding-bottom: 6rem;
    }

    .services__wrapper {
        flex-direction: row;
        -webkit-box-align: stretch;
        align-items: stretch;
        -webkit-box-pack: center;
        justify-content: center;
    }

    .service {
        width: 33.3333%;
    }

    .testimonials__container {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .testimonials__wrapper {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        max-width: none;
        flex-direction: row;
        -webkit-box-align: stretch;
        align-items: stretch;
    }

    .testimonials__small--header {
        display: none;
    }

    .testimonials__header {
        display: block;
    }

    .testimonials__title {
        text-align: left;
    }

    .testimonials__description {
        margin-left: 0px;
        margin-right: 0px;
        max-width: none;
        text-align: left;
        font-size: 1.125rem;
    }

    .carousel {
        margin: auto;
    }

    .footer__container {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .contact__title {
        font-size: 3.5rem;
    }

    .contact__description {
        font-size: 1.125rem;
    }

    .about__wrapper {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .about__description {
        margin-right: 4rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .about__description-right {
        margin-left: 4rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .about__title {
        font-size: 3rem;
    }

    .about__para {
        font-size: 1.125rem;
    }

    .about__para--address {
        font-size: 1.125rem;
    }
}

@media (min-width: 1280px) {

    .header__para {
        font-size: 1.125rem;
    }
}
